.review-sec {
  padding: 100px 0;
  background-image: url("/public/asset/images/others/girl5.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.review-sec::before {
  content: "";
  display: block;
  background-color: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0.3;
  z-index: 1;
}
.review-title {
  color: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
.review-slide .item {
  background-color: var(--main);
  border-radius: 20px;
  padding: 50px;
  height: 350px;
}
.re-img img{
  height: 150px;
  border-radius: 50%;
  margin-left: 80px;
}
.review-txt {
  color: #fff;
}
.review-person {
  display: flex;
  align-items: center;
}
.review-person .img-box {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.review-person .img-box img{
  height: 100%;
}
.review-person .name-box p{
  margin: 0;
  padding: 0;
  color: #fff;
}
