.all-package {
  padding: 100px 0;
  background: url("/public/asset/images/bg/compos-flight.webp");
    background-size: contain;
    background-repeat: no-repeat;
}
.package-cont {
  width: 60%;
}
@media (max-width:992px){
  .package-cont {
    width: 100%;
  }
}
.package-cont .cursive-heading,
.package-cont .main-heading {
  margin-bottom: 20px;
}

.pop-package {
  margin-top: 80px;
  position: relative;
}
.pop-package .item {
  border-radius: 20px;
  height: 400px;
  overflow: hidden;
}
.pop-package .item::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  opacity: 0.3;
  top: 0;
  transition: all 0.3s ease;
}
.pop-package .item img {
  filter: saturate(2);
  height: 400px;
}
.pop-package .item .item-cont {
  position: absolute;
  bottom: 0;
  color: #fff;
  z-index: 1;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
}
.pop-package .item .item-cont a{
  opacity: 0;
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.pop-package .item:hover::after{
  opacity: 0.7;
}
.pop-package .item:hover .item-cont{
  bottom: 130px;
}
.pop-package .item:hover .item-cont a{
  opacity: 1;
}