.pd-banner-sec{
    padding: 20px 20px 0px 20px;
    background-color: #f9f4f0;
}

.pd-header{
    padding: 40px 0;
}

.pd-header .pd-head-cont{
    display: flex;
    justify-content: end;
    /*align-items: center;*/
}

.pd-head-cont > div:not(:first-child){
    /*margin-left: 30px;*/
}


.pd-head-cont div p{
    margin: 0;
    margin-left: 5px;
}

.pd-head-cont .icon-box{
    background-color: var(--main);
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pd-head-cont .icon-box span{
    color: #fff;
    font-size: 25px;
}
/*@media (width < 560px) {*/
/*    .pd-header .pd-head-cont{*/
/*        display: none;*/
/*    }*/
/*}*/
.filter-box{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 30px;
}
.filter-box select{
    height:60px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.pd-main-sec{
    padding: 10px 0;
}

.pd-main-sec hr{
    margin: 50px 0;
}

.pd-main-sec .detail-box{
    padding: 30px;
}

.pd-main-sec .inclusion-box li{
    list-style: none;
    margin-bottom: 20px;
    font-weight: bold;
}

.pd-main-sec .inclusion-box li span{
    color: var(--main);
}

.pd-main-sec iframe{
    width: 100%;
    border-radius: 20px;
}
.price-col{
    position: relative;
}
.pd-main-sec .price-box{
    box-shadow: 0 0 10px lightgrey;
    padding: 30px;
    border-radius: 20px;
    background: url("/public/asset/images/others/enquiry-map.png");
    /*background-color:#faebd7;*/
    background-size: cover;
    position: sticky;
    top: 0;
}

.pd-main-sec .price-box .price-head{
    display: flex;
}

.pd-main-sec .price-box .price-head .price{
    width: 80%;
    text-align: right;
}

.pd-main-sec .price-box .price-head p{
    padding: 0;
    margin: 0;
}

.pd-main-sec .price-box .form-box input,
.pd-main-sec .price-box .form-box textarea{
    width: 80%;
}

.package-box{
    height:300px;
    overflow:hidden;
}
@media (width < 992px){
    .package-box{
    height:fit-content;
    overflow:visible;
}
.img-cont{
    display: none;
}
}
