.main-menu {
  height: 85px;
}
.main-menu .navbar-brand img {
  height: 60px;
}
.main-menu .nav-link:hover {
  color: var(--main);
}
.main-menu .nav-item .active {
  color: var(--main);
  font-weight: 600;
}
.main-menu .nav-item {
  margin-right: 30px;
}
@media (max-width: 1200px) {
  .main-menu .nav-item {
    margin-right: 0px;
  }
}
.main-menu .nav-link {
  color: black;
  font-weight: 500;
}
.nav-scr {
  --bs-scroll-height: 300px;
}
.social {
  position: fixed;
  z-index: 10;
  right: 10px;
  top: 200px;
}
.social div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: azure;
  font-size: 20px;
}
.social div:hover i {
  animation-name: social;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes social {
  100% {
    transform: rotate(360deg);
  }
}
.s-media1 {
  background-image: var(--blueG);
}
.s-media2 {
  background-image: var(--instaG);
}
.s-media3 {
  background-image: var(--youG);
}
.s-media4 {
  background-image: var(--whatG);
}
.pil-menu {
  white-space: nowrap;
}
.sub-menu-box {
  background-image: url("/public/asset/images/bg/compos.webp") !important;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.sub-menu-box h6 {
  color: var(--main);
}
.trending-packageitem {
  background-color: white;
}

.main-headd {
  background-color: var(--sec);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.visa-head-color {
  margin-top: 12px;
}
.visa-con-bx {
  padding: 0px;
}
.visa-con-bx h5 {
  color: #fff;
}
