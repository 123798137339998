.data-sec{
    padding: 100px 0;
    background-image: url("/public/asset/images/bg/world1.webp");
    background-size: cover;
    background-repeat: no-repeat;
}
@media (max-width: 786px) {
    .data-sec{
        padding: 50px 0;
    }
}
.data-sec .data-cont .para{
    margin-top: 30px;
}
.data-links{
    margin-top: 50px;
}
.data-img{
    border-radius: 500px 0 0 500px;
    overflow: hidden; 
}
.data-img img{ 
    height: 400px;
}
.progress{
    height: 5px;
}
.progress div{
    background-color: var(--sec);
}
.bar-1{
    width: 90%;
}
.bar-2{
    width: 80%;
}
.data-sec-inn .row{
    justify-content: center;
    align-items: center;
}
