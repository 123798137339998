.about-service {
  margin: 0 auto;
}
.trending-package{
  border: 1px solid red !important;
}
.blog-para{
  color: #ffff !important;
}
.about-service .about-top-sec {
  padding: 50px 0px;
}
.visa__title{
  color: var(--main) !important;
  margin: 12px 0px;
}
 

/* visa */
.visa__search__filter__head{
  display: flex;
  justify-content: center;
}
.visa__search__filter  button{
  border: none;
  background: none;
  color: #8b8ba7;
}

.visa__search__filter {
  --timing: 0.3s;
  --width-of-input: 200px;
  --height-of-input: 40px;
  --border-height: 2px;
  --input-bg: #fff;
  --border-color: #2f2ee9;
  --border-radius: 30px;
  --after-border-radius: 1px;
  position: relative;
  width: var(--width-of-input);
  height: var(--height-of-input);
  display: flex;
  align-items: center;
  padding-inline: 0.8em;
  border-radius: var(--border-radius);
  transition: border-radius 0.5s ease;
  background: var(--input-bg,#fff);
}
/* styling of Input */
.input__visa {
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
}
.visa__search__filter:before {
  content: "";
  position: absolute;
  background: #1E2D58;
  transform: scaleX(0);
  transform-origin: center;
  width: 100%;
  height: var(--border-height);
  left: 0;
  bottom: 0;
  border-radius: 1px;
  transition: transform var(--timing) ease;
}
.visa__search__filter:focus-within {
  border-radius: var(--after-border-radius);
}

.input__visa:focus {
  outline: none;
}
.visa__search__filter:focus-within:before {
  transform: scale(1);
}
.input__visa::placeholder {
  margin-left: -60px !important;
}
.reset__visa{
  border: none;
  background: none;
  opacity: 0;
  visibility: hidden;
}
.input__visa:not(:placeholder-shown) ~ .reset__visa  {
  opacity: 1;
  visibility: visible;
}
.visa__search__filter svg {
  width: 17px;
  margin-top: 3px;
}

@media (max-width: 768px) {
  .about-top-sec {
    margin: 0 auto;
    text-align: center;
  }
}
/*.about-service .service-sec {*/
/*  padding: 20px 0px;*/
/*}*/
/*.about-service .service-sec .mini-heading {*/
/*  padding-top: 40px;*/
/*}*/
.about-service .cursive-heading {
  margin-bottom: 20px;
}
.about-service .main-heading span,
.anima {
  color: #fff;
  position: relative;
  animation: vacation 3s infinite;
  z-index: 1;
}
.about-service .main-heading span::before,
.anima::before {
  content: "";
  height: 100%;
  position: absolute;
  top: -2px;
  z-index: -1;
  width: 100%;
  background-color: var(--main);
  animation: vacation 3s infinite 1s;
}
@keyframes vacation {
  0% {
    width: 0;
  }
  20% {
    width: 100%;
  }
}
.about-book {
  background-color: #1e2d59;
  color: #fff;
  overflow: hidden;
}
.ran {
  overflow: hidden;
}
.ran img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .about-book img {
    height: 100px;
    width: auto;
    overflow: hidden;
  }
}
@media (max-width: 768px) {
  .about-book img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 576px) {
  .about-book img {
    width: 100%;
    height: auto;
  }
  .book-sec {
    margin: 20px auto;
    text-align: center;
  }
}
.about-service .about-testimonial {
  margin: 0 auto;
  text-align: center;
  padding: 50px;
}
.about-service .about-testimonial span::before {
  content: "";
  height: 100%;
  position: absolute;
  top: -2px;
  z-index: -1;
  width: 100%;
  background-color: #1e2d59;
  animation: vacation 3s infinite 1s;
}
@keyframes vacation {
  0% {
    width: 0;
  }
  20% {
    width: 100%;
  }
}
.abouttesti img {
  width: 100%;
  height: 400px;
  border-radius: 5%;
  object-fit: cover;
}
.testimonial-con {
  margin: 0 auto;
  padding: 50px 50px;
}
.testimonial-con .imgbox {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonial-con .imgbox img {
  height: 100%;
  width: 100%;
}
.testimonial-con .testi-author {
  margin: 0 auto;
  padding: 20px;
}
.testimonial-imgallery {
  margin: 0 auto;
  padding-top: 20px;
}
.testimonial-imgallery .testbox {
  padding: 10px;
}
.testimonial-imgallery .testbox img {
  width: 300px;
  height: 200px;
  border-radius: 10%;
  object-fit: cover;
  padding: 5px;
}
@media (max-width: 992px) {
  .testimonial-con .testi-author {
    margin: 0 60px;
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .testimonial-con .testi-author {
    margin: 60px;
    padding: 0px;
  }
  .testimonial-imgallery {
    display: none;
  }
}
@media (max-width: 768px) {
  .abt-blog-sec {
    padding: 0px !important;
  }
}
.footer-bg {
  height: 80px;
  /* background-image: url("../asset/images/Blue\-\Map@3x-100.jpg");*/
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: auto 0 0 0;
}

.abt-blog-sec {
  padding: 100px 0;
}
.abt-blog-sec {
  /* background-image: url("../asset/images/bg/map-2side-fill.webp"); */
  background-size: contain;
}
.blog-row-1 {
  margin-bottom: 50px;
}
.abt-blog-box {
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}
.abt-blog-box::before {
  content: "";
  height: 50%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) 80%);
  position: absolute;
  bottom: 0;
}
.abt-blog-box .cont {
  position: absolute;
  padding: 20px;
  bottom: 0;
  color: #fff;
}

.abt-blog-box a {
  color: #fff;
  background-color: #e17149;
  padding: 3px 10px;
  border-radius: 30px;
}
.abt-blog-box .cont .mini-heading {
  margin-top: 20px;
}
.progress {
  height: 5px;
}
.progress div {
  background-color: var(--sec);
}
.bar-2 {
  width: 90%;
}

/* About Main Section */
.main-sec {
  padding: 0px 0px 20px 0px;
}
.main-img-box {
  width: 100%;
  margin: auto 0;
}
.main-img-box img {
  width: 100%;
  height: auto;
}
.img-1 {
  width: 100%;
  top: 75px;
  left: 60px;
  border-radius: 20px;
}
.img-2 {
  width: 150px;
  border-radius: 15px;
  left: 180px;
  top: 20px;
}
.img-3 {
  width: 200px;
  border-radius: 15px;
  right: 0;
  z-index: -1;
}
.img-4 {
  width: 120px;
  border-radius: 15px;
  right: 0;
  bottom: 20px;
}
.img-5 {
  height: 180px;
  border-radius: 20px;
  bottom: 0;
}

.testimonial-con .testi-author{
    margin:0 auto;
    padding:20px;
}
.testimonial-imgallery{
    margin:0 auto;
    padding-top:20px;
}
.testimonial-imgallery .testbox{
    padding:10px;
}
.testimonial-imgallery .testbox img{
    width:300px;
    height:200px;
    border-radius: 10%;
    object-fit: cover;
    padding:5px;
}
@media (max-width:992px){
    .testimonial-con .testi-author{
        margin:0 60px;
        padding:0px;
    }
}
@media (max-width:768px){
    .testimonial-con .testi-author{
        margin:60px;
        padding:0px;
    }
    .testimonial-imgallery{
        display: none;
    }
}
@media (max-width:768px){
    .abt-blog-sec{
        padding:0px !important;
    }
}
.footer-bg{
    height: 80px;
    background-image: url("/public/asset/images/bg/footer-map.webp");
    background-size: 300px ;
    background-repeat: no-repeat;
}
.cont a:hover{
    color:var(--sec) !important;
}
.travel-agent-highlight a{
  text-decoration: none !important;
  color: black !important;
}