.footer-sec {
  padding: 70px 0;
  background-color: var(--sec);
}
.footer-sec .txt-box,
.footer-box .add-box,
.footer-box .contact-box {
  color: #fff;
}
 .social-icons {
  margin-top: 30px;
}
.social-icons a {
  margin-right: 15px;
  color: #fff;
}
.footer-box .contact-box .phone-box a {
  color: #fff !important;
}
.footer-box .contact-box .mini-heading {
  margin-bottom: 30px;
}
.footer-box .contact-box .phone-box {
  margin-top: 30px;
}
.infy-sec {
  background-color: var(--sec);
}
.infy-box {
  display: flex;
  justify-content: end;
}
.infy-box a {
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 10px;
}
.infy-box a p {
  margin: 0;
  padding: 0;
  margin-right: 10px;
}
.infy-box a img {
  width: 100px;
}
.add-box {
  margin-bottom: 30px !important;
}
.second-row .mini-heading {
  color: var(--main);
}
.second-row li {
  list-style: none;
  margin-bottom: 15px;
}
.second-row li a {
  color: #fff;
}
.address-box{
  margin: 30px 0;
  color: #fff;
}
.address-box p{
  color: var(--main);
}
