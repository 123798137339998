
.services-main{
    margin:0 auto;
    text-align: center;
    padding:50px 10px;
}
.sub-heading{
  font-weight: 900 !important;
font-size: 40px;
}
.services-main .headtitle{
    padding-bottom:30px !important;
}
.service-list li{
    list-style-type:none;
}
.service-list i{
    padding:15px;
}
.about-top-sec{
    margin:0px auto 50px auto;
    /* background-image: url("/public/asset/images/bg/map-bg1.webp"); */
    background-repeat: no-repeat;
    background-size:contain;
}
@media (max-width:360px){
    .about-top-sec .animate-text{
        font-size:medium;
    }
}
.about-top-sec .cursive-heading{
    padding-bottom:50px;
}
.service-sec img{
    width:100px;
}
.about-top-sec .ser{
    padding-bottom:10px;
}
.service-bg{
    margin:0 auto;
    display: flex;
    align-items: center;
    /* background-image: url("/public/asset/images/girl4.jpg"); */
    background-size:cover;
    height: 500px;
    color:black;
}
.service-counter{
    margin:0 auto;
    padding:10px;
}
.service-counter .counter-box{
    margin:20px;
    /*border: 1px solid grey;*/
    text-align:center;
    padding:40px;
    background-color:antiquewhite;
    border-radius:20px;
}
.service-counter .counter-para{
    margin:0 auto;
    padding:20px;
}

.service-im-box{
    margin:20px;
    padding:20px;
    height: 100%;
}
.service-im-box img{
    object-fit: cover;
    width: 100%;
}
.main-box{
    margin:50px 0px 0px 0px;
}
.service-main{
    background-image: none;
    color:black;
    background-color: antiquewhite;
}
.service-main button{
    margin-top:20px;
}
.visa-banner{
    height: 500px;
    /* background-image: url("/public/asset/images/travel_bg.webp"); */
    background-size: cover;
    background-position: 0 -200px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab-menu-sec{
    background-color: var(--sec) !important;
    width: 100%;
    margin-bottom: 30px;
}
.tab-box{
    text-align: center;
    height: 50px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    text-decoration: none;
    color:#fff;
    border-bottom: 1px solid #fff;
}
.tab-name{
    margin: 0;
    padding: 0;
    font-weight: bold;
}
.tab-menu a:hover{
  color:var(--main)  !important;
} 
.tab-menu .row .active{
    background-color: var(--main) !important;
    color: #fff;
}
.service-box .img-box img{
    width: 100%;
}
.service-box .row{
    justify-content: center;
    align-items: center;
}
.services-main {
  margin: 0 auto;
  text-align: center;
  padding: 50px 10px;
}
.services-main .headtitle {
  padding-bottom: 30px !important;
}
.service-list li {
  list-style-type: none;
}
.service-list i {
  padding: 15px;
}
.about-top-sec {
  margin: 0px auto 50px auto;
  background-image: url("/public/asset/images/world-full-map.webp");
  background-size: contain;
}
@media (max-width: 360px) {
  .about-top-sec .animate-text {
    font-size: medium;
  }
}
.about-top-sec .cursive-heading {
  padding-bottom: 50px;
}
.service-sec img {
  width: 100px;
}
.about-top-sec .ser {
  padding-bottom: 10px;
}
.service-bg {
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-image: url("/public/asset/images/others/girl2.webp");
  background-size: cover;
  height: 500px;
  color: black;
}
.service-counter {
  margin: 0 auto;
  padding: 80px;
}
/*.service-counter .counter-box {*/
/*  margin: 20px;*/
  /*border: 1px solid grey;*/
/*  text-align: center;*/
/*  padding: 40px;*/
/*}*/
.service-counter .counter-para {
  margin-top: 50px;
  padding: 20px;
}
@media (max-width: 576px) {
 .service-counter{
   padding:30px;  
 }
}
.serv-form-section {
  height: 100%;
  width: 100%;
  padding: 40px;
}
.ser-box{
    height:150px;
    display:flex;
    justify-content:center;
    align-items:center;
}
.serv-box{
    height:100px;
    /*display:flex;*/
    /*justify-content:center;*/
    /*align-items:left;*/
}
.serv-form-section .row {
  background-color: antiquewhite;
}
.serv-form-section .form-img-box {
  height: 100%;
}
.serv-form-section .form-img-box img {
  height: 100%;
}
.serv-form-section .mini-form {
  margin: 0px 0px;
  background-color: antiquewhite;
  padding: 20px 30px;
}
@media(width<600px){
  .serv-form-section .mini-form {
    margin: 0px 0px;
    background-color: antiquewhite;
    padding: 20px 0px;
  }
  }
.serv-form-section .bg1 {
  padding: 0;
}
.serv-form-section .quick-form {
  margin: 10px auto;
  text-align: center;
}
.serv-form-section .quick-form-box input,
textarea {
  margin: 10px auto;
  display: block;
  padding: 10px;
  width: 100%;
}
.serv-form-section .portfolio-slides {
  height: 150px !important;
  background-color: var(--sec);
  display:flex;
  align-items:center;
  justify-content:center;
}
.service-im-box {
  margin: 20px;
  padding: 20px;
  height: 100%;
}
.service-im-box img {
  object-fit: cover;
  width: 100%;
}
.nav{
    background-color:var(--sec) !important;
}
/*.main-box {*/
/*  margin: 20px 0px 0px 0px;*/
/*}*/
.service-main {
  background-image: none;
  color: black;
  background-color: antiquewhite;
}
.service-main button {
  margin-top: 20px;
}
.visa-banner {
  height: 500px;
  /* background-image: url("/public/asset/images/travel_bg.webp"); */
  background-size: cover;
  background-position: 0 -200px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-menu-sec {
  background-color: #eff3f6 !important;
  width: 100%;
}
.tab-menu .row {
  height: 50px;
}

.tab-box {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--main);
}
.tab-name {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.tab-menu .row{
  background-color: var(--sec) !important;
  color: #fff;
}
.service-box .row {
  justify-content: center;
  align-items: center;
}
#sv-box,
#tv-box,
#ti-box,
#wpv-box {
  display: none;
}
.dis-box {
  display: block !important;
}
.dis-none {
  display: none !important;
}
.port .item {
  padding: 30px;
}
.main-box {
  margin: 50px 0px;
}
@media(width<991px){
    .tap-menu-sec{
        height:100px !important;
    }
    .tab-content{
        margin-top:80px !important;
    }
}
@media(width<600px){
.textstyle{
  font-size: 30px;
}
}
/* .main-head{
  background: radial-gradient(circle, rgba(242,129,16,1) 35%, rgba(255,55,0,1) 100%);
  border-radius: 5px;
} */
.visa-head-color{
  /* color:#fff !important; */
  display: flex;
  align-items: center;

}
