
 a:hover{
    }
    .topbar{
        position:fixed;
        height:50px;
        width:50px;
        border-radius:50%;
        background-color:var(--main);
        display:flex;
        justify-content:center;
        align-items:center;
        right:10px;
        bottom:50px;
    }
    .topbar a{
        color:#fff;
    }
    .topbar a:hover{
        color:#fff !important;
    }
    .contact-box a:hover{
        color:#fff !important;
    }
    .seo-keywords{
        margin-top: 30px;
        font-size: 11px;
      }
      .text-light-color{
        color: rgba(255, 254, 254, 0.733);
      }
      .Keywordstopsearch{
        color: var(--main);
        font-size: 18px;
    font-weight: 600;
      }
      .tick-icon{
        color: white;
      }
      .seo-keywords {
        font-size: 12px;
        margin-top: 30px;
    }
    .seo-keywords li{
      margin-bottom: 10px;
    }
    