
/* Submenu Style  */
.sub-menu-box {
    position: absolute;
    top: 0;
    left: 160px;
    background-color: #fff;
    padding: 20px;

    border-radius: 10px;
    opacity: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }
  .sub-menu-box1{
    padding-right: 90px !important;
  }
  .sub-menu-box2{
    padding-bottom: 60px !important;
  }
  /* .blog-body{
    padding-top: 20px;
} */
.blog-main .blogg__name{
  text-decoration: none;
  color: rgb(33, 37, 41);
}
.blog-main .blogg__name:hover{
  text-decoration: none;
  color: rgb(33, 37, 41) !important;
}
  .sub-menu-box ul {
    padding-left: 20px;
  }
  
  .sub-menu-box ul li {
    margin-bottom: 5px;
  }
  
  .sub-menu-box .col-3 {
    width: fit-content;
  }
  
  .sub-menu-box .row {
    width: fit-content;
  }
  
  .sub-menu-row {
    flex-wrap: nowrap;
  }
  
  .continetal-bx,
  .india-bx {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }
  
  .cbox1:hover .continetal-bx,
  .cbox2:hover .india-bx
  /* .cbox3:hover .honeymoon-bx,
  .cbox4:hover .pilgrimage-bx  */
  {
    opacity: 1;
    pointer-events: auto;
  }
  
  .india-bx .col-12 {
    width: fit-content;
    white-space: nowrap;
  }
  /* .honeymoon-bx .col-12 {
    width: fit-content;
    white-space: nowrap;
  }
  .pilgrimage-bx .col-12 {
    width: fit-content;
    white-space: nowrap;
  } */


  .continetal-bx a{
    text-decoration: none;
    color: #000;
    font-weight: 400;
  }

  .continetal-bx a:hover{
    color: var(--main) !important;
  }

  .cm-ul a {
    color: black;
    font-weight: 400;
  }
  


  .icons-img:hover {
    animation: flip 1s;
  }

  .social1 {
    margin-right: 20px;
    display: flex;
    gap: 5px
  }

  .social1 div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: azure;
    font-size: 15px;
  }

  .social1 div:hover i {
    animation-name: social;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  @keyframes social {
    100% {
      transform: rotate(360deg);
    }
  }

  .s-media1 {
    background-image: var(--blueG);
  }

  .s-media2 {
    background-image: var(--instaG);
  }

  .s-media3 {
    background-image: var(--youG);
  }

  .s-media4 {
    background-image: var(--whatG);
  }

  /* Submenu Style  */
  .sub-menu-box {
    position: absolute;
    /* left:160px; */
    top:0;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    opacity: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    left: 100%;
    /* right: 0; */
  }
  .sub-menu-box ul{
    padding-left: 20px;
  }
  .sub-menu-box ul li{
    margin-bottom: 5px;
  }
  .sub-menu-box .col-3{
    width: fit-content;
  }
  .sub-menu-box .row{
    width: fit-content;
  }
  .sub-menu-row{
    flex-wrap: nowrap;
  }
  .continetal-bx, .india-bx{
    transition: all 500ms;
  }
  .cbox1:hover .continetal-bx{
      opacity: 1;
  }
  .cbox2:hover .india-bx{
    opacity: 1;
  }
  .india-bx .col-12{
    width: fit-content;
    white-space: nowrap;
  }.cm-ul a{
    color:black;
    font-weight: 400;
  }




/* 
@keyframes logo-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */

.navbar-brand img {
  scale: 1.1;
}
.highlightintern a{
  text-decoration: none;
  color: black;
  }