.cont-sec{
    padding: 100px 0;
    /* background-image: url("../asset/images/bg/world-full-map.webp"); */
    background-image: url("/public/asset/images/bg/world-full-map.webp");
    background-size: cover;
}
.cont-head{
    display: flex;
    align-items: center;
}
/* .form-box form input{
  display: block;
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 15px 30px;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 20px;
} */
.cont-head p{
    margin: 0;
    padding: 0;
    margin-left: 10px;
}
.cont-head .circle{
    background-color: var(--main);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cont-head .circle span{
    color: #fff;
}
.cont-sec {
  padding: 100px 0;
}
.cont-head {
  display: flex;
  align-items: center;
}
.cont-head p {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}
.cont-head .circle {
  background-color: var(--main);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont-head .circle span {
  color: #fff;
}
.add-1,
.call-1,
.call-2,
.mail {
  margin-top: 30px;
}
.call-1 a,
.call-2 a,
.mail a {
  color: var(--grey);
}
.form-section{
    margin-bottom:50px;
}
.form-section .container {
  background-color: #f9f4f0;
  padding: 100px 50px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px lightgrey;
}
.form-head {
  text-align: center;
  margin-bottom: 50px;
}
.form-head .para {
  width: 50%;
  margin: 0 auto;
}
@media (width < 768px) {
  .form-head .para {
    width: 100%;
  }
}
@media (width < 576px){
  .form-section .container {
  padding: 30px 0px 0px 0px !important;
  border-radius: 10px;}
  .form-section {
  margin-bottom:150px;}
}
.form-box form input,
.form-box form textarea{
  display: block !important;
  border: 1px solid var(--gray) !important;
  border-radius: 5px !important;
  padding: 15px 30px !important;
  width: 60% !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}

select{
      display: block;
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 15px 30px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}
  
/* About Main Section */
.main-sec{
    margin:50px 0px 20px 0px;
}
@media (max-width:768px) {
    .main-img-box img{
        margin:50px auto 0px auto;
    }
}
/* .main-sec{
    background-image: url(/asset/images/world-flight.jpg);
    background-size:cover;
} */
.main-img-box{
  
    width: 100%;
    margin:auto 0;
}
.main-img-box img{
    width:100%;
    height:auto;
}
.img-1{
    width: 100%;
    top: 75px;
    left: 60px;
    border-radius: 20px;
}
.img-2{
   width: 150px;
   border-radius: 15px;
   left: 180px;
   top:20px
}
.img-3{
    width: 200px;
    border-radius: 15px;
    right: 0;
    z-index: -1;
}
.img-4{
    width: 120px;
    border-radius: 15px;
    right: 0;
    bottom: 20px;
}
.img-5{
    height: 180px;
    border-radius: 20px;
    bottom: 0;
}
.form-box .btns {
  background-color: var(--main);
  text-align: center;
  font-weight: bold;
  color: #fff;
}
@media (width<600px) {
  .form-box form input,
.form-box form textarea{
  display: block !important;
  border: 1px solid var(--gray) !important;
  border-radius: 5px !important;
  padding: 15px 30px !important;
  width: 90% !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}
}
.text-para-align{
  text-align: center;
}
/* .contact-travel{
  position: relative;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 0;
} */
.travel-main-sec{
  display: flex;
  gap: 10px;
  
}
.travel-main-sec li{
  list-style: none;

}
.contact-home-interlink a{
  text-decoration: none;
  color: var(--main);
}
.travel-agency-contact{
  font-size: 20px;
}
.travel-agency-contact-main{
  font-size: 25px;
}
.internlink a{
  text-decoration: none;
  color: var(--gray);
}
.para-linter a{
  text-decoration: none !important;
  color: rgb(131, 127, 127) !important;
}
.travel-agency-contact-main a{
  text-decoration: none;
  color: rgb(20, 20, 20);
}
.customer-review-keyword a{
  text-decoration: none !important;
  color: white !important;
  }
  .trend-title a{
    text-decoration: none;
    color: var(--main);
  }
  .trend-title a:hover{
  
   color: var(--main) !important;
  }