/* Font Family */
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Root */
:root {
  --main: #f0870d;
  --sec: #1e2d58;
  --gray: #929292;
  --blueG: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  --instaG: linear-gradient(
    to right top,
    #ae00a6,
    #e1007f,
    #f93057,
    #fa6833,
    #eb9612
  );
  --youG: linear-gradient(
    to right top,
    #ff0000,
    #ef0000,
    #df0000,
    #cf0000,
    #c00000
  );
  --whatG: linear-gradient(
    to right top,
    #22ff00,
    #1dd803,
    #18b304,
    #138f04,
    #0e6d04
  );
}
* {
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box !important;
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.blog-details-content{
color: #000;
margin-top: 150000px !important;

}
.para {
  color: var(--gray);

}
.nav-item a:hover{
  color: var(--main) !important;
}
.contact-link a:hover{
  color: var(--main) !important;
}
.large-heading {
  font-size: 70px;
  font-weight: 800;
}

@media (max-width: 1200px) {
  .large-heading {
    font-size: 80px;
  }
}

@media (max-width: 992px) {
  .large-heading {
    font-size: 70px;
  }
}

@media (max-width: 768px) {
  .large-heading {
    font-size: 60px;
  }
}

.main-heading {
  font-size: 50px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .main-heading {
    font-size: 40px;
    font-weight: 600;
  }
}

@media (max-width: 992px) {
  .main-heading {
    font-size: 30px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .main-heading {
    font-size: 20px;
    font-weight: 600;
  }
}

.sub-heading {
  font-size: 35px;
  font-weight: 700;
}

@media (max-width: 992px) {
  .sub-heading {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .sub-heading {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .sub-heading {
    font-size: 20px;
  }
}

.mini-heading {
  font-size: 20px;
  font-weight: 700;
}

.cursive-heading {
  font-family: "Dancing Script", cursive;
  font-size: 30px;
  font-weight: 700;
  color: var(--main);
}

.btns {
  padding: 10px 20px;
  font-weight: 600;
  background-color: var(--main);
  border: 0;
  color: #fff;
  border-radius: 5px;
}
.btns-navy{
  padding: 10px 20px;
  font-weight: 600;
  background-color: var(--sec);
  border: 0;
  color: #fff;
  border-radius: 5px;
}

a {
  text-decoration: none;
  font-weight: bold;
}

.cont-banner {
  height: 300px;
  background-image: url("/public/asset/images/others//orange1.webp");
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont-bannerr {
  height: 400px;
  /* background-image: url("../asset/images/others/banner2.webp"); */
  background-image: url("/public/asset/images/others/banner2.webp"); 
  background-size: cover;
  background-position: bottom;
  display: flex;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cont-bannerrr {
  height: 400px;
  /* background-image: url("../asset/images/others/banner2.webp"); */
  background-image: url("/public/asset/images/others/banner2.webp"); 
  background-size: cover;
  background-position: bottom;
  display: flex;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cont-bannerrrr {
  height: 400px;
  /*background-image: url("../asset/images/others/banner5.jpg"); */
  /* background-image: url("/aspire/public/asset/images/others/banner2.webp");  */
  background-size: cover;
  background-position: bottom;
  display: flex;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cont-banner .banner-box {
  color: #fff;
  text-align: center;
}

.pagination{
    margin-right: 15px !important;
}

.pagesBox{
    display:flex;
    justify-content:center;
}

a:hover{
    color: #000 !important;
}
.tit-box{
  height: 50px;
}
.price-col {
  position: sticky;
  top: 20px; /* Adjust this value to suit your layout */
}
