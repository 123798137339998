.blog-sec{
    padding: 20px 0 50px 0;
}
.blog-head{
    text-align: center;
    margin-bottom: 80px;
}
.blog-head p{
    width: 60%;
    margin: 0 auto;
}
.main-blog-item .blog-img{
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
}
.main-blog-item .blog-body a:first-child{
    color: #fff;
    background-color: var(--main);
    padding: 3px 10px;
    border-radius: 5px;
}
.main-blog-item .blog-body .sub-heading{
    margin: 20px 0;
}
.main-blog-item .blog-body p{
    margin-bottom: 30px;
}
.sub-blog-item .row{
    margin-bottom: 20px;
}
.sub-blog-item .blog-img{
    border-radius: 20px;
    overflow: hidden;
}
.sub-blog-item .blog-body a:first-child{
    color: #fff;
    background-color: var(--main);
    padding: 3px 10px;
    border-radius: 5px;
}
.sub-blog-item .blog-body .mini-heading{
    margin: 20px 0;
}
@media (max-width:768px) {
    .blog-sec{
        padding: 50px 0;
    }
    .blog-img{
        margin-bottom: 10px;
    }
    .main-blog-item{
        margin-bottom: 40px;
    }
}
@media (max-width:992px) {
    .blog-head p{
        width: 100%;
    }
}