.tour-explore{
    padding: 100px 0 100px 0;
}
.box-inn{
    padding: 50px 10px;
}
.min-about li{
    list-style-type: none;
    padding:8px;
}
.min-about i{
    padding:15px;
 }
.main-tour-box{
    margin:0 auto;
}
.tour-box-1{
    background-image: url("/public/asset/images/others/rome_international.webp");
}
.tour-box-2{
    background-image: url("/public/asset/images/others/tajmahal_Domestic.webp");
    background-position: 50px -10px;
}
.tour-box-3{
    background-image: url("/public/asset/images/others/Pilgrimage.webp");
}
.tour-box-4{
    background-image: url("/public/asset/images/others/couple_honeymoon.webp");
}
.tour-box-5{
    background-image: url("/public/asset/images/others/visa-tour.webp");
}
.box-inn .icon-box{
    background-color: var(--sec);
    overflow: hidden;
    border-radius: 10px 0 0 10px;
}
.box-inn .icon-box img{
    width: 80px;
    filter: invert() hue-rotate(35deg) brightness(1.5);
}
.img-trend{
    opacity: 0.3;
}
.box-inn .icons-box{
    background-repeat: no-repeat;
    background-position: -180px 0px;
    background-size: cover;
    overflow: hidden;
    border-radius: 10px;
    
}
.icons-box, .txt-box{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    filter: drop-shadow(0 0 5px black);
}
.icon-box, .txt-box{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
}
.txt-box{
    justify-content: start;
}
.tour{
    height: 130px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
}
.trend{
    color:var(--gray);
    background-image: url("/public/asset/images/bg/map-piece1.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    padding-bottom: 100px;
}
.trend .trend-title{
    margin-bottom: 20px;
}
.trend .trending-packageitem{
    position: relative;
    box-shadow: 0 0 10px lightgrey !important;
}

.trend .card-img-top{
    /*height:250px;*/
}
.trend .mini-heading{
    margin-top: 20px;
    color:black;
}
.trend .trend-price{
    color: black;
    text-align: end;
}
.card-img-top{
    height: 250px !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
@media (width<=800px) {
    .card-img-top{
    height: 320px !important;
}
}
.trend .day-plan{
    height:40px;
    width: 90%;
    position: absolute;
    top:55%;
    left:5%;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 2px 3px 0px;
    color:var(--gray);
    padding:10px;
}
.trend .day-plan i{
    color: var(--main);
}
.trend .day-plan .day-map i:first-child{
    margin-right:10px;
}
.about-sec{
    background-image: url("/public/asset/images/others/bg111.webp");
    background-size: cover;
    background-repeat:no-repeat;
    /* margin:80px auto; */
    margin-top: 50px;
    padding:100px;
    color:white;
}
@media (max-width:576px) {
    .about-sec{
    padding:30px;
    }
}
.about-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-owl .item{
    height: 100%;
}
.price-content-box{
    padding: 100px 0;
}
.price-first-box{
    margin:0 auto;
    text-align: center;
    padding:40px;
}
.price-content-box .para{
    width:60%;
    margin: 20px auto 0 auto;
}
@media (max-width:992px) {
    .price-content-box .para{
        width:100%;
    }
}
.price-content-box .box1-con{
    background-color:#1e2d58;
    border-radius: 10px;
    padding:50px 0;
    color:#fff;
}
.price-content-box ul{
    width: fit-content;
    margin: 0 auto 20px auto;
    padding: 0;
}
.price-content-box .box2-con{
    background-color:#d57c48;
    border-radius: 10px;
    padding:50px 0;
    color:#fff;
}
.price-content-box .box3-con{
    background-color:#1e2d58;
    border-radius: 10px;
    padding:50px 0;
    color:#fff;
}
.price-content-box li{
    list-style-type: none;
    margin-top: 20px;
}
.price-content-box i{
   margin-right: 10px;
}
.price-content-box .mini-heading{
    margin:0 auto;
    text-align: center;
    padding-bottom: 20px;
}
.price-content-box .main-heading{
    margin:0 auto;
    text-align: center;
}
.price-content-box .price{
    font-size:20px !important;
    padding-left:10px;
}
.price-content-box button{
    margin:0 auto;
    display: flex;
    align-items: center;
}
.price-content-box{
    background-image:url("/public/asset/images/bg/flight-path.webp");
    background-size: contain;
    background-repeat: no-repeat;
}

/* .travel-video-box{
    background-image:url(../asset/images/Map\ -\ 1.@3x-100.jpg);
    background-size: cover;
} */
