.search-sec{
    padding: 100px 0;
    /* background-image: url("../asset/images/bg/fta-full.webp"); */
    background-image: url("/public/asset/images/bg/fta-full.webp");
    background-size: cover;
}
.search-cont {
    margin-bottom: 80px;
}
.search-box{
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
}
.ico-box{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: var(--main);
}
.form-label{
    font-weight: bold;
}
input ,
select{
    /* all: unset !important; */
}
.search-box .btns{
    margin-top: 10px;
}
.unset-form{
    all: unset !important;

}
