body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Font Family */
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Root */
:root {
  --main: #f0870d;
  --sec: #1e2d58;
  --gray: #929292;
  --blueG: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  --instaG: linear-gradient(
    to right top,
    #ae00a6,
    #e1007f,
    #f93057,
    #fa6833,
    #eb9612
  );
  --youG: linear-gradient(
    to right top,
    #ff0000,
    #ef0000,
    #df0000,
    #cf0000,
    #c00000
  );
  --whatG: linear-gradient(
    to right top,
    #22ff00,
    #1dd803,
    #18b304,
    #138f04,
    #0e6d04
  );
}
* {
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box !important;
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.pack-heading{
  color: #000;
}



.para {
  color: var(--gray);
}
.large-heading {
  font-size: 70px;
  font-weight: 800;
}

@media (max-width: 1200px) {
  .large-heading {
    font-size: 80px;
  }
}

@media (max-width: 992px) {
  .large-heading {
    font-size: 70px;
  }
}

@media (max-width: 768px) {
  .large-heading {
    font-size: 60px;
  }
}

.main-heading {
  font-size: 50px;
  font-weight: 700;
  
}

@media (max-width: 1200px) {
  .main-heading {
    font-size: 40px;
    font-weight: 600;
  }
}

@media (max-width: 992px) {
  .main-heading {
    font-size: 30px;
    font-weight: 600;
    
  }
}

@media (max-width: 768px) {
  .main-heading {
    font-size: 20px;
    font-weight: 600;
  }
}

.sub-heading {
  font-size: 35px;
  font-weight: 700;
}

@media (max-width: 992px) {
  .sub-heading {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .sub-heading {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .sub-heading {
    font-size: 20px;
  }
}

.mini-heading {
  font-size: 20px;
  font-weight: 700;
}

.cursive-heading {
  font-family: "Dancing Script", cursive;
  font-size: 30px;
  font-weight: 700;
  color: var(--main);
}

.btns {
  padding: 10px 20px;
  font-weight: 600;
  background-color: var(--main);
  border: 0;
  color: #fff;
  border-radius: 5px;
}
.btns-navy {
  padding: 10px 20px;
  font-weight: 600;
  background-color: var(--sec);
  border: 0;
  color: #fff;
  border-radius: 5px;
}

a {
  text-decoration: none;
  font-weight: bold;
}

.cont-banner {
  height: 300px;
  /* background-image: url("/aspire/public/asset/images/others/orange1.webp"); */
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont-bannerr {
  height: 400px;
  /* background-image: url("/aspire/public/asset/images/others/orange1.webp"); */
  background-size: cover;
  background-position: bottom;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cont-bannerrr {
  height: 400px;
  /* background-image: url("/aspire/public/asset/images/others/orange1.webp"); */
  background-size: cover;
  background-position: bottom;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cont-bannerrrr {
  height: 400px;
  /* background-image: url("/aspire/public/asset/images/others/orange1.webp"); */
  background-size: cover;
  background-position: bottom;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cont-banner .banner-box {
  color: #fff;
  text-align: center;
}

.pagination {
  margin-right: 15px !important;
}

.pagesBox {
  display: flex;
  justify-content: center;
}

a:hover {
  color: #000 !important;
}
.tit-box {
  height: 50px;
}

.main-menu .navbar-brand img {
  height: 60px;
}

html {
  width: 100vw;
  /* overflow-x: hidden; */
}

.in-inner img {
  scale: 0.8;
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  aspect-ratio: 1/1;
}

.img-cover {
  object-fit: cover;
  object-position: 0 0;
}

.clr a:hover {
  color: #fff !important;
}

.blog-body a:hover {
  color: #fff !important;
}

/* service */
.form-section {
  height: 100%;
  width: 100%;
  padding: 40px;
}

.form-section .row {
  background-color: antiquewhite;
}

.form-section .form-img-box {
  height: 100%;
}

.form-section .form-img-box img {
  height: 100%;
}

.form-section .mini-form {
  margin: 0px 0px;
  background-color: antiquewhite;
  padding: 20px 30px;
}

.form-section .bg1 {
  padding: 0;
}

.form-section .quick-form {
  margin: 10px auto;
  text-align: center;
}

.form-section .quick-form-box input,
textarea {
  margin: 10px auto;
  display: block;
  padding: 10px;
  width: 100%;
}

@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.count {
  animation: counter 5s linear forwards;
  counter-reset: num var(--num);
  font: 800 40px system-ui;
  padding: 2rem;
}

.count::after {
  content: counter(num);
}

@keyframes counter {
  from {
    --num: 0;
  }

  to {
    --num: 1000;
  }
}

/* .count {
  animation: counter 5s linear forwards;
  counter-reset: num var(--num);
  font: 800 40px system-ui;
  padding: 2rem;
}

.count::after {
  content: counter(num);
}

@keyframes counter {
  from {
    --num: 0;
  }

  to {
    --num: 1000;
  }
} */

.sm {
  height: 30px;
  width: 30px;
  padding: 0px 1px;
}

.icons-img:hover {
  animation: flip 1s;
}

.social1 {
  margin-right: 20px;
  display: flex;
  gap: 5px;
}

.social1 div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: azure;
  font-size: 15px;
}

.social1 div:hover i {
  animation-name: social;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes social {
  100% {
    transform: rotate(360deg);
  }
}

.s-media1 {
  background-image: var(--blueG);
}

.s-media2 {
  background-image: var(--instaG);
}

.s-media3 {
  background-image: var(--youG);
}

.s-media4 {
  background-image: var(--whatG);
}

/* service */
/* Submenu Style  */
.sub-menu-box {
  position: absolute;
  top: 0;
  left: 160px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

.sub-menu-box ul {
  padding-left: 20px;
}

.sub-menu-box ul li {
  margin-bottom: 5px;
}

.sub-menu-box .col-3 {
  width: fit-content;
}

.sub-menu-box .row {
  width: fit-content;
}

.sub-menu-row {
  flex-wrap: nowrap;
}

.continetal-bx,
.india-bx {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.cbox1:hover .continetal-bx,
.cbox2:hover .india-bx {
  opacity: 1;
  pointer-events: auto;
}

.india-bx .col-12 {
  width: fit-content;
  white-space: nowrap;
}

.cm-ul a {
  color: black;
  font-weight: 400;
}

.cm-ul a:hover {
  color: #f0870d !important;
  font-weight: 400;
}

/* contact */
.cont-btn {
  width: 60%;
  margin: 0 auto;
}

.con-map {
  padding: 100px 10px;
}

@media (max-width: 576px) {
  .con-map {
    padding: 10px 20px;
  }
}

/* blogdetail */
.pd-main-sec {
  background-image: url("/public/asset/images/bg/world1.webp");
  background-size: cover;
  background-repeat: no-repeat !important;
}

/* continental */

.banner-cont {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.banner-cont img {
  width: 100%;
}

.cnt-para {
  font-weight: 300 !important;
}

.cnt-card {
  height: 450px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.cnt-card .card-namee{
  text-align: center;
}
.as {
  background-image: url("/public/asset/images/country/asia.webp");
}

.pr {
  background-image: url("/public/asset/images/country/pacific.webp");
}

.me {
  background-image: url("/public/asset/images/country/east.webp");
}

.af {
  background-image: url("/public/asset/images/country/africa.webp");
}

.am {
  background-image: url("/public/asset/images/country/america.webp");
}

.eu {
  background-image: url("/public/asset/images/country/europe.webp");
}

.card-name {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  color: #fff;
  filter: drop-shadow(5px 5px 5px black);
}

.card-name p {
  transition: all 100ms;
}

.cnt-card-cont {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 10px;
  top: 450px;
  transition: all 500ms;
}

.cnt-card:hover .cnt-card-cont {
  top: 0;
}

.cnt-card:hover .card-name p {
  visibility: hidden;
}

.cnt-sec {
  background: url("/public/asset/images/bg/world1.webp");
  background-size: cover;
}

.cnt-sec .row .col-md-4 {
  padding: 50px;
}

@media (width <=1400px) {
  .cnt-sec .row .col-md-4 {
    padding: 20px;
  }
}

/* Asia */

.banner-cont {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.banner-cont img {
  width: 100%;
}

.cnt-para {
  font-weight: 300 !important;
}

.cnt-card {
  height: 450px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 300ms;
}

.cnt-card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.cnt-card:hover .card-namee p {
  color: var(--main);
}

.card-namee p {
  transition: all 300ms;
}

.card-namee {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  color: #fff;
  filter: drop-shadow(5px 5px 5px black);
}

.cnt-card-cont {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 10px;
  top: 450px;
  transition: all 500ms;
}

.cnt-sec {
  background: url("/public/asset/images/bg/world1.webp");
  background-size: cover;
}

.country-img {
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: -200px 0;
}

/* admin page */

/* <style> */
.log-body {
  width: 400px;
  background-color: #fff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  padding: 30px;
}
@media (width < 450px) {
  .log-body {
    width: 95%;
  }
}
/* </style> */

/* Detailspackage */
/* <style> */
/* Country Flag Position */
.search-input {
  border: 1px solid grey !important;

}
.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  margin-top: 38px !important;
}

.social1 {
  margin-right: 20px !important;
  display: flex !important;
  gap: 5px;
}

.social1 div {
  width: 30px !important;
  height: 30px;
  border-radius: 50% !important;
  margin-bottom: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: azure !important;
  font-size: 15px !important;
}

.social1 div:hover i {
  animation-name: social !important;
  animation-duration: 1s !important;
  animation-iteration-count: infinite !important;
}

@keyframes social {
  100% {
    transform: rotate(360deg) !important;
  }
}

.s-media1 {
  background-image: var(--blueG) !important;
}

.s-media2 {
  background-image: var(--instaG) !important;
}

.s-media3 {
  background-image: var(--youG);
}

.s-media4 {
  background-image: var(--whatG) !important;
}

.icons-img:hover {
  animation: flip 1s !important;
}

.cont-btn {
  width: 60% !important;
  margin: 0 auto !important;
}

.iti {
  position: relative !important ;
  display: inline !important ;
  margin-left: 10% !important;
}

.iti input,
.iti input[type="text"],
.iti input[type="tel"] {
  z-index: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 18px !important;
  padding-right: 36px !important;
  margin-right: 0 !important;
  margin-left: 10% !important;
}

.form-boxx form .date-st{
  display: block !important;
  border: 1px solid var(--gray) !important;
  margin-bottom: 20px !important;
  width: 80%;
  padding: 5px 30px !important;
  margin-left: 34px;
}
.form-boxx .ant-picker-input>input::placeholder {
  color: #868686 !important;
  font-weight: 450;
  font-size: 17px;
}

.form-boxx {

  /* background-image: url("/public/asset/images/others/enquiry-bg.webp") !important; */
}
.form-boxx form .detail-input
{
  display: block !important;
  border: 1px solid var(--gray) ;
  border-radius: 5px !important;
  padding: 15px 30px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
  height: 20px;
}
.form-boxx form .phone-us
{
  display: block !important;
  border: 1px solid var(--gray) ;
  border-radius: 5px !important;
  padding: 0px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
  width: 80%;
  background-color: #fff;
  /* height: 20px; */
}
.form-boxxx form .phone-us
{
  display: block !important;
  border: 1px solid var(--gray) ;
  border-radius: 5px !important;
  padding: 0px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
  /* width: 80%; */
  background-color: #fff;
  /* height: 20px; */
}
.form-boxxx form .phone-us input{
  width: 100%;
  padding-left: 50px !important;

}
.form-boxx form .phone-us input{
 border: none;
 outline: none;
}
.form-boxx form textarea {
  display: block !important;
  border: 1px solid var(--gray) !important;
  border-radius: 5px !important;
  padding: 15px 30px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}


.form-boxxx form input
{
  display: block !important;
  border: 1px solid var(--gray) ;
  border-radius: 5px !important;
  padding: 15px 30px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
  height: 20px;
}
.form-boxxx form textarea {
  display: block !important;
  border: 1px solid var(--gray) !important;
  border-radius: 5px !important;
  padding: 15px 30px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}

 .form-boxxx textarea {
  width: 80%;
}

.pd-main-sec .price-box .form-boxx input,
.pd-main-sec .price-box .form-boxx textarea {
  width: 80%;
}

.in-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.in-icon span {
  font-size: 25px !important;
  color: #fff !important;
}

.in-inner {
  gap: 5px !important;
}

.highlights div {
  gap: 20px !important;
}

.highlights img {
  filter: hue-rotate(-149deg) !important;
}

.in-title {
  font-weight: bold !important;
}



/* select {
  height: 30px !important;
  padding: 0 !important;
} */

.accordion-button:not(.collapsed) {
  color: var(--main) !important;
  background-color: #fff !important;
}

.pd-main-sec {
  background-image: url("/public/asset/images/bg/world1.webp") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.pd-head-cont > div {
  /*    width:200px;*/
  margin-bottom: 20px;
}

.ico-in {
  justify-content: center !important;
}

@media (width <=1400px) {
  .pd-head-cont {
    flex-wrap: wrap !important;
    justify-content: start !important;
    align-items: start !important;
  }
}

@media (width <=992px) {
  .pd-head-cont {
    flex-wrap: nowrap !important;
  }

  .in-tit {
    display: none !important;
  }

  .navbar-collapse {
    z-index: 10 !important;
    background: rgb(255, 255, 255) !important;
  }
}

@media (width <=767px) {
  .pd-head-cont {
    flex-wrap: wrap !important;
    justify-content: start !important;
  }
}

.accordion-item h5 {
  font-size: 18px !important;
  line-height: 30px !important;
}

.sm {
  height: 30px !important;
  width: 30px !important;
  padding: 0px 1px !important;
}

.footer-sec a:hover {
  color: var(--main) !important;
}

.topbar {
  position: fixed !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  background-color: var(--main) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  right: 10px !important;
  bottom: 10px !important;
  z-index: 20 !important;
}

.topbar a {
  color: #fff !important;
}

.topbar a:hover {
  color: #fff !important ;
}

/* visaaa */
    .filter-con {
        position: sticky !important;
        top: 0px !important;
        height: fit-content;
    }

    .icons-img:hover {
        animation: flip 1s;
    }

    .amt-box {
        position: relative;
    }

    #amount-val {
        position: absolute;
        bottom: 60px;
        color: #fff;
        background-color: #000;
        padding: 5px;
        font-size: 14px;
        display: none;
    }

    .show-amt {
        display: block !important;
    }

    .in-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .in-icon span {
        font-size: 25px;
        color: #fff;
    }

    .in-inner {
        gap: 5px;
    }

    .search-input {
        border: 1px solid grey !important;
    }

    /* visa page  */

    .visabx {
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }

    .visabx:hover {
        scale: 0.9;
        transition: 1s;
    }

    .visa-con-bx {
        background-color: #ffffff !important;
        border-bottom: 5px solid var(--main);
        padding: 10px;
    }

    .visa-con-bx h5 {
        color: var(--main);
    }

    .visa-item-box {
        border-radius: 10px;
    }
    .visa-item-img{
        height: 180px;
        overflow: hidden;
    }
    .visa-item-img img{
        width: 100%;
    }

    


.visa-head-height{
  height: 50px;

}
.fee-highlight{
  color: var(--main) !important;
  font-weight: 700;
  font-size: 20px;
}
.visa-head-color{
  font-weight: 700;

}
.Overflowhide {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
/* .price-box{
  border: 1px solid red;
  position: sticky !important;
  top: 0 !important;
} */