.travel-video{
    background-image: url("/public/asset/images/bg/map-2side.webp");
    background-repeat: no-repeat;
    background-size: cover;
}
.travel-video-box .large-heading{
    padding: 100px 0 50px 0;
    text-align: center;
}
.video-box{
    display: flex;
    justify-content: center;
}
.partner-box{
    padding: 100px 0 100px 0;
}