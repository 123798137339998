/* .package-sec {
  padding: 150px 0;
} */
*{
  /* border: 1px solid red; */
}
.visa-packk{
  /* padding: 100px 0px; */
}
.packagess{
  padding: 60px 0px;
}
.form-selectt{
  display: block;
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 0px !important;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.package-sec .package-box {
  box-shadow: 0px 0px 10px lightgrey;
  border-radius: 10px;
  /* height: 300px;
  overflow: hidden; */
}

.filter-box hr {
  margin: 50px 0;
}

.filter-box select,
.filter-box input:not(input[type="checkbox"]) {
  width: 100%;
  /* height: 50px !important; */
  border: none;
}

.package-box .item-box .item-cont {
  padding: 15px;
}

.item-box .item-cont .para span {
  color: var(--main);
}

.item-box .item-cont .price-cont {
  display: flex;
  align-items: center;
}

.item-box .item-cont .price-cont div {
  text-align-last: right;
  width: 80%;
}

.item-box .item-cont .price-cont div .para {
  margin: 0;
}
.package-box .item-box .item-img {
  position: relative;
}
.package-box .item-box .item-img .img-cont {
  position: absolute;
  width: 90%;
  height: 43px;
  top:250px;
  transform: translate(5%); 
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}
.package-box .item-box .item-img {
  height: 100%;
}
.package-box .item-box .item-img img {
  height: 100%;
}
.package-box .item-box .item-img .img-cont span {
  color: var(--main);
}

.filter-box input[type="range"] {
  -webkit-appearance: none;
}

.filter-box input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

.filter-box input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--main);
  margin-top: -5px;
}

.filter-box input[type="range"]:focus {
  outline: none;
}

.filter-box input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--sec);
}

/* package */
.filter-con {
  position: sticky !important;
  top: 0px !important;
  height: fit-content !important;
}

.icons-img:hover {
  animation: flip 1s;
}

.amt-box {
  position: relative;
}

#amount-val {
  position: absolute;
  bottom: 45px;
  color: #fff;
  background-color: #000;
  padding: 5px;
  font-size: 14px;
  display: none;
}

.show-amt {
  display: block !important;
}

.in-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.in-icon span {
  font-size: 25px;
  color: #fff;
}

.in-inner {
  gap: 5px;
}

.search-input {
  border: 1px solid grey !important;
    height: 50px !important;

}
